<template>
  <div class="container">
    <div class="row justify-content-center pt-5 pb-3">
      <div class="col-9 col-xl-7 position-relative g-0">
        <div
          class="blended rounded"
          :style="{ 'min-height': backHeight + 'px' }"
        ></div>
        <div
          class="
            d-flex
            flex-column
            justify-content-between
            text-white
            position-absolute
            top-0
            start-0
            p-3
            w-100
          "
          ref="infoBox"
        >
          <div
            class="
              d-flex
              flex-column
              text-start
              pt-3
              pb-2
              px-2 px-xl-4         
              dynamic-font
            "
          >
            <span
              >Where language learning and gameplay are seamlessly combined.
              When you play it, you get:</span
            >
            <hr class="m-3 mx-4" />
            <ul>
              <li>True fun and challenge</li>
              <li>Effective language training</li>
              <li>Solution for low motivation</li>
              <li>Endless game experience</li>
            </ul>
            <hr class="mb-3 mt-1 mx-4" />
            <span>Join our social communities to test a private prototype</span>
          </div>
          <div class="d-flex flex-wrap justify-content-evenly mt-4 mb-2">
            <a
              href="https://www.reddit.com/r/speechclash/"
              type="button"
              class="
                btn btn-outline-light
                fw-bold
                flex-fill
                mb-3 mb-xl-1
                mx-2 mx-xl-5
                py-2 
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-reddit"
                viewBox="0 0 16 16"
              >
                <path
                  d="M6.167 8a.831.831 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661zm1.843 3.647c.315 0 1.403-.038 1.976-.611a.232.232 0 0 0 0-.306.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0 .213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83.458 0 .83-.381.83-.83a.831.831 0 0 0-1.66 0z"
                />
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.828-1.165c-.315 0-.602.124-.812.325-.801-.573-1.9-.945-3.121-.993l.534-2.501 1.738.372a.83.83 0 1 0 .83-.869.83.83 0 0 0-.744.468l-1.938-.41a.203.203 0 0 0-.153.028.186.186 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224c-.02.115-.029.23-.029.353 0 1.795 2.091 3.256 4.669 3.256 2.577 0 4.668-1.451 4.668-3.256 0-.114-.01-.238-.029-.353.401-.181.688-.592.688-1.069 0-.65-.525-1.165-1.165-1.165z"
                />
              </svg>
              &nbsp;Reddit
            </a>
            <a
              href="https://discord.com/channels/951544593709678672/960923767440302110"
              type="button"
              class="
                btn btn-outline-light
                fw-bold
                flex-fill
                mb-3 mb-xl-1
                mx-2 mx-xl-5
                py-2 
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-discord"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"
                ></path>
              </svg>
              &nbsp;Discord
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Subscriber",
  data() {
    return { backHeight: 0 };
  },
  mounted() {
    this.backHeight = this.$refs.infoBox.clientHeight;
  },
};
</script>
<style scoped>
.blended {
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
  z-index: -1;
  mix-blend-mode: overlay;
}
.dynamic-font {
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (3440 - 300)));
  font-family: PTSans;
  text-shadow: 1px 0px 1px black, 0px 1px 1px black, -1px 0px 1px black,
    0px -1px 1px black, 0px 4px 4px black;
}
</style>

