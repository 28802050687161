<template>
  <div class="container px-2 pt-4 pb-0">
    <div class="col mt-3 mb-0">
      <h1 class="display-1">{{ msg }}</h1>
      <hr />
      <h3 class="text-white text-capitalize mt-3" style="font-family: Shadow">
        first linguistic card battler
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "MailSubscribe",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-family: "DO0mD";
  text-transform: uppercase;
  color: white;
  text-shadow: -2px 0 0 #99bc21, 2px 0 0 #ff001a, 0px 10px 4px black;
}

h3 {
  text-shadow: 2px 0px 2px black, 0px 2px 2px black, -2px 0px 2px black, 0px -2px 2px black;
}
hr {
  border: 0;
  height: 2px !important;
  opacity: 1 !important;
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 25%,
    white 25%,
    white 47%,
    transparent 47%,
    transparent 49%,
    white 49%,
    white 51%,
    transparent 51%,
    transparent 53%,
    white 53%,
    white 75%,
    transparent 75%,
    transparent 100%
  );
}
</style>
