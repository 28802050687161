<template>
  <div class="container-fluid hexgrid pb-5">
    <main-header msg="Speech Clash" />
    <video-player link="https://youtu.be/I-pkQZt3q0A" />
    <subscriber />
  </div>
</template>

<script>
import MainHeader from "./components/MainHeader.vue";
import VideoPlayer from "./components/VideoPlayer.vue";
import Subscriber from "./components/Subscriber.vue";

console.log("");

export default {
  name: "App",
  components: {
    MainHeader,
    VideoPlayer,
    Subscriber,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  box-shadow: rgba(0, 0, 0, 0.5) 10vw -60px 100px 22px inset,
    rgba(0, 0, 0, 0.5) -10vw 30px 100px 22px inset;
}

@font-face {
  font-family: "DO0mD";
  src: local("DO0mD"), url(assets/fonts/DO0mD.ttf) format("truetype");
}

@font-face {
  font-family: "Shadow";
  src: local("Shadow"), url(assets/fonts/Shadow_regular.ttf) format("truetype");
}

@font-face {
  font-family: "PTSans";
  src: local("PTSans"), url(assets/fonts/PTSans-Regular.ttf) format("truetype");
}

</style>