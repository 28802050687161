import { createApp } from 'vue'
import App from './App.vue'
import { createGtm } from '@gtm-support/vue-gtm';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


createApp(App).use(createGtm({
  id: 'GTM-WM96S9K'    
  })).mount('#app')
