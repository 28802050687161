<template>
  <div class="container">
    <div class="row justify-content-center pt-2 pb-4">
      <div class="col-9 col-xl-6">
        <div class="ratio ratio-16x9">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/I-pkQZt3q0A" title="Speech clash first trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>А      
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "VideoPlayer",

  props: {
    link: String,
  },
};
</script>


